.container {
  margin: 0 auto;
  width: 360px;
  position: relative;
  top: calc(50% - 160px);
  p {
    padding: 0 20px;
    margin-top: 20px;
    margin-bottom: 50px;
  }
  @media (max-height: 560px) {
    p {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
  img {
    width: 20px;
    height: 40px;
    transform: rotate(55deg);
    position: relative;
    top: -80px;
    left: 233px;
  }
  button {
    width: 335px;
    height: 66px;
  }
  @media (hover: hover) {
    button:hover {
      background-color: rgba(103, 176, 219, 1);
    }
  }
}
