.customScrollbarsContainer {
  width: 100%;
  height: 450px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  .customScrollbarsContent {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 10px;
  }
}

.customScrollbarsContent::-webkit-scrollbar {
  display: none;
}

.customScrollbarsScrollbar {
  flex-basis: 25px;
  box-sizing: border-box;

  .customScrollbarsTrackAndThumb {
    margin: 0;
    width: 25px;
    height: 450px;
    background-image: url('../../assets/images/scrollbar.svg');
    background-repeat: no-repeat;
    position: relative;
    margin-top: 15px;
    left: 10px;

    .customScrollbarsThumb {
      width: 25px;
      background-image: url('../../assets/images/penis-classic.svg');
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      cursor: pointer;
      left: -10px;
    }
  }
}

@media (max-height: 660px) {
  .customScrollbarsContainer {
    height: 350px;
  }
  .customScrollbarsScrollbar {
    .customScrollbarsTrackAndThumb {
      height: 350px;
    }
  }
}

@media (max-height: 530px) {
  .customScrollbarsContainer {
    height: 290px;
  }
  .customScrollbarsScrollbar {
    .customScrollbarsTrackAndThumb {
      height: 290px;
    }
  }
}
