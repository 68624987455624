.PopopContainer {
  box-sizing: border-box;
  width: 335px;
  background-color: rgba(255, 255, 255, 1);
  border: 2px solid black;
  border-radius: 10px;
  position: fixed;
  z-index: 3;
  left: calc(50vw - 335px / 2);
  top: calc(50% - 290px / 2);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  user-select: none;

  p {
    margin-bottom: 30px;
  }

  .firstButton {
    margin-bottom: 15px;
  }

  button {
    border: 2px solid black;
    border-radius: 10px;
    width: 265px;
    height: 61px;
    user-select: none;
  }
  @media (hover: hover) {
    button:hover {
      background-color: rgba(103, 176, 219, 1);
    }
  }
}

@media (max-height: 560px) {
  .PopopContainer {
    top: calc(50% - 290px / 2 + 20px);
  }
}
