.mainContainer {
  height: 100%;
  width: 100vw;
  overflow: hidden;
  background-color: rgba(122, 196, 240, 1);
}
.BallImage {
  position: fixed;
  height: 95px;
  width: 47.5px;
  z-index: 2;
  transform: translateZ(0);
  will-change: auto;
}

.container {
  background-image: url('../../shared/assets/images/bg-game.svg');
  background-color: rgba(180, 227, 255, 1);
  height: 666px;
  width: 2000000px;
  background-repeat: repeat-x;
  background-position: center left;
  position: relative;
  top: calc(50% - 350px);
  transition: left 150ms linear;
  transform: translateZ(0);
  will-change: transform;

  .legImg {
    height: 370px;
    position: fixed;
    bottom: 230px;
    left: -60px;
  }
  .cursorImage {
    position: absolute;
    bottom: 130px;
    left: calc(50vw - 32px);
    width: 56px;
    height: 95px;
    z-index: 10;
  }
  p {
    position: absolute;
    bottom: 40px;
    left: calc(50vw - 100px);
    z-index: 10;
  }

  @media (min-width: 1400px) {
    p {
      text-shadow: -0 -2px 0 #ffffff, 0 -2px 0 #ffffff, -0 2px 0 #ffffff, 0 2px 0 #ffffff,
        -2px -0 0 #ffffff, 2px -0 0 #ffffff, -2px 0 0 #ffffff, 2px 0 0 #ffffff, -1px -2px 0 #ffffff,
        1px -2px 0 #ffffff, -1px 2px 0 #ffffff, 1px 2px 0 #ffffff, -2px -1px 0 #ffffff,
        2px -1px 0 #ffffff, -2px 1px 0 #ffffff, 2px 1px 0 #ffffff, -2px -2px 0 #ffffff,
        2px -2px 0 #ffffff, -2px 2px 0 #ffffff, 2px 2px 0 #ffffff, -2px -2px 0 #ffffff,
        2px -2px 0 #ffffff, -2px 2px 0 #ffffff, 2px 2px 0 #ffffff;
    }
  }
}
.container:focus {
  outline: none;
  border: none;
}

@media (max-height: 740px) {
  .container {
    .cursorImage {
      bottom: 180px;
    }
    p {
      bottom: 90px;
    }
  }
}

@media (max-height: 640px) {
  .container {
    height: 560px;
    top: calc(50% - 280px);
  }
}

@media (max-height: 560px) {
  .container {
    .cursorImage {
      bottom: 240px;
    }
    p {
      bottom: 150px;
    }
  }
}
