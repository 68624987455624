.menuContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  padding: 0 18px;
  box-sizing: border-box;
  position: fixed;
  bottom: 35px;
  z-index: 4;

  .buttonContainer {
    display: flex;
    width: 180px;
    justify-content: space-between;
    .disabledLink {
      pointer-events: none;
    }
  }
  .button {
    width: 50px;
    height: 50px;
    background-color: white;
    border: 2px solid black;
    border-radius: 10px;
    cursor: pointer;

    img {
      position: relative;
    }

    .arrowIcon {
      width: 18px;
      top: 14px;
    }

    .soundIcon {
      width: 25px;
      top: 12px;
    }

    .pauseIcon {
      width: 12px;
      top: 16px;
    }
    .playIcon {
      top: 12px;
    }
  }

  .hoverable:hover {
    background-color: rgba(180, 227, 255, 1);
  }

  .active {
    background-color: rgba(180, 227, 255, 1);
  }

  .disabledButton {
    pointer-events: none;
    &:hover {
      background-color: white;
    }
  }

  .counter {
    width: 120px;
    height: 50px;
    background-color: white;
    border: 2px solid black;
    border-radius: 10px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;

    img {
      width: 35px;
    }
    div {
      font-size: 33px;
      margin-top: 7px;
      margin-left: 5px;
    }
  }
}
