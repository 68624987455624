.container {
  position: relative;
  margin: 0 auto;
  top: calc(50% - 100px);
  h2 {
    margin-bottom: 45px;
  }
  .items {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 350px;
    margin: 0 auto;
    a {
      user-select: none;
    }
    .BallItem {
      width: 75px;
      height: 115px;
      border: 2px solid black;
      border-radius: 10px;
      background-color: white;
      user-select: none;

      img {
        height: 85px;
        position: relative;
        top: 15px;
        user-select: none;
        pointer-events: none;
      }
    }
    @media (hover: hover) {
      .BallItem:hover {
        background-color: rgba(134, 210, 255, 1);
      }
    }
  }
}
