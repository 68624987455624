.container {
  margin: 0 auto;
  width: 260px;
  height: 350px;
  position: relative;
  top: calc(50% - 155px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    margin-bottom: 25px;
    margin-top: 25px;
  }

  .button {
    height: 66px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      position: relative;
      margin-left: 10px;
    }
  }

  @media (hover: hover) {
    .button:hover {
      background-color: rgba(103, 176, 219, 1);
    }
  }
}

@media (max-height: 560px) {
  .container {
    height: 270px;
    top: calc(50% - 100px);
  }
}
