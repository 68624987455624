.container {
  background-color: rgba(180, 227, 255, 1);
  width: 100vw;
  height: 100%;
  box-sizing: border-box;
  .contentContainer {
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 1);
    border: 2px solid black;
    border-radius: 10px;
    width: 335px;
    height: 625px;
    margin: 0 auto;
    position: relative;
    top: calc(50% - 648px / 2);
    padding: 30px;
    padding-right: 0;

    h2 {
      font-size: 30px;
      line-height: 33.6px;
    }
    ul {
      padding: 0;
      list-style: none;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid black;
        margin-bottom: 10px;
        padding-bottom: 10px;
        .name {
          font-size: 22px;
          float: left;
          max-width: 180px;
          overflow: hidden;
        }
        .counter {
          width: 75px;
          height: 37px;
          background-color: white;
          border: 2px solid black;
          border-radius: 10px;
          display: flex;
          flex-wrap: nowrap;
          justify-content: center;

          img {
            width: 22px;
          }
          div {
            font-size: 18px;
            margin-top: 8px;
            margin-left: 5px;
          }
        }
      }
    }
    button {
      margin-top: 15px;
      font-size: 25px;
      position: relative;
      left: -15px;
    }
    @media (hover: hover) {
      button:hover {
        background-color: rgba(103, 176, 219, 1);
      }
    }
    .scrollBar {
      position: absolute;
      right: 12px;
      height: 450px;
      top: calc(50% - 275px);
    }
    .BallIcon {
      position: absolute;
      width: 21px;
      height: 42px;
      top: 100px;
      right: 2px;
    }
  }
}

@media (max-height: 660px) {
  .container {
    .contentContainer {
      height: 525px;
      top: calc(50% - 525px / 2);
      .scrollBar {
        right: 12px;
        height: 350px;
        top: calc(50% - 275px);
      }
    }
  }
}

@media (max-height: 530px) {
  .container {
    .contentContainer {
      padding: 20px 0px 20px 30px;
      height: 450px;
      top: calc(50% - 450px / 2);
      .scrollBar {
        right: 12px;
        height: 290px;
        top: calc(50% - 225px);
      }
    }
  }
}
