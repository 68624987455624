.button {
  width: 50px;
  height: 50px;
  background-color: white;
  border: 2px solid black;
  border-radius: 10px;
  cursor: pointer;

  img {
    position: relative;
  }

  .soundIcon {
    width: 25px;
    top: 12px;
  }
}

.hoverable:hover {
  background-color: rgba(180, 227, 255, 1);
}

.active {
  background-color: rgba(180, 227, 255, 1);
}

.disabledButton {
  pointer-events: none;
  &:hover {
    background-color: white;
  }
}
