.container {
  width: 100vw;
  height: 100%;
  background-color: rgba(180, 227, 255, 1);
  overflow: hidden;

  .buttonContainer {
    position: relative;
    left: calc(50vw - 25px);
    top: calc(50% - 250px);
  }
  @media (max-height: 560px) {
    .buttonContainer {
      top: calc(50% - 210px);
    }
  }
}
