.Onboarding {
  background-color: rgba(180, 227, 255, 1);
  width: 100vw;
  height: 100%;
  .sound {
    position: fixed;
    left: calc(50vw - 25px);
    top: calc(50% - 265px);
  }
  @media (max-height: 560px) {
    .sound {
      top: calc(50% - 220px);
    }
  }
}
